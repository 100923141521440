import { Injectable } from '@angular/core';
import CreativeEditorSDK, {
  AssetDefinition,
  AssetResult,
  AssetSource,
  AssetsQueryResult,
  CompleteAssetResult,
  CreativeEngine,
  SceneMode,
  UserInterfaceElements,
} from '@cesdk/cesdk-js';
import {
  Media,
  Maybe,
  ResourceType,
  MediaVisibilityType,
  GetProfileTemplatesGQL,
  Folder,
} from '@designage/gql';
import { CurrentUserService, MediaService } from '@desquare/services';
import { getAssetNormalThumbUrl } from '@desquare/utils';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IProfile } from '@desquare/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SourcesService {
  constructor(
    private currentUserService: CurrentUserService,
    private getProfileTemplatesGql: GetProfileTemplatesGQL,
    private mediaService: MediaService,
    private http: HttpClient,
  ) {}

  async loadTemplateSource(
    engine: CreativeEngine,
    sceneMode: SceneMode,
    applyAsset?:
      | ((asset: AssetResult) => Promise<number | undefined>)
      | undefined,
  ) {
    const sourceId =
      sceneMode === 'Design' ? 'ly.img.template' : 'ly.video.template';
    engine.asset.addLocalSource(sourceId, undefined, applyAsset);

    const assets = (await this.getProfileTemplates())?.filter(
      (m) =>
        m.type ===
        (sceneMode === 'Design' ? ResourceType.Image : ResourceType.Video),
    );

    if (assets && assets.length > 0)
      assets.forEach((m) => {
        const template: AssetDefinition = {
          id: m.id,
          label: { en: m.name },
          meta: {
            uri: m.source?.url as string,
            thumbUri: getAssetNormalThumbUrl(m.secureUrl),
            blockType: '//ly.img.ubq/scene',
          },
        };

        // console.log('template', template); // DEBUG

        engine.asset.addAssetToSource(sourceId, template);
      });
  }

  async uploadSource(engine: CreativeEngine) {
    engine.asset.addLocalSource('io.designage.upload');
  }

  addSources = async (
    engine: CreativeEngine,
    sceneMode: SceneMode,
    folderIds: () => Promise<string[]>,
    profileFolders: Folder[],
  ) => {
    engine.asset.addSource({
      id: 'designageImage',
      findAssets: async (
        queryData,
      ): Promise<AssetsQueryResult<CompleteAssetResult>> => {
        return await findAssets(ResourceType.Image, queryData?.groups);
      },
      applyAsset: async (assetResult) => {
        return engine.asset.defaultApplyAsset(assetResult);
      },
      // applyAssetToBlock: async (assetResult, block) => {
      //   engine.asset.defaultApplyAssetToBlock(assetResult, block);
      // },
      getGroups: folderIds,
      addAsset: () => true,
      removeAsset: () => true,
    });

    if (sceneMode === 'Video') {
      engine.asset.addSource({
        id: 'designageVideo',
        findAssets: async (queryData) => {
          return await findAssets(ResourceType.Video, queryData?.groups);
        },
        applyAsset: async (assetResult) => {
          return engine.asset.defaultApplyAsset(assetResult);
        },
        getGroups: folderIds,
        addAsset: () => true,
        removeAsset: () => true,
      });
    }

    const getFolderTags = (folderId: Maybe<string>) => {
      const folder = folderId
        ? profileFolders.find((x) => x.id === folderId)
        : undefined;
      return folder ? folder.fullPath?.split('/') : [];
    };

    const findAssets = async (
      type: ResourceType,
      groups?: string[],
    ): Promise<AssetsQueryResult<CompleteAssetResult>> => {
      // note: currently we don't allow medias in multiple groups (folders)
      // so we can say for sure that groups array will always have 1 element
      const groupId = groups ? groups[0] : undefined;
      const profileId = this.currentUserService.currentProfile?.id;
      let media;
      if (profileId) {
        media = await this.mediaService.getProfileMediaForCreativeEditor(
          profileId,
          groupId,
        );
      }
      // console.log('findAssets', media?.length); // DEBUG
      const assets: CompleteAssetResult[] = media
        ? media
            .filter(
              (m) =>
                m.type === type && m.visibility === MediaVisibilityType.Default,
              // client side filtering
              // && groups?.includes(m.folderId ?? 'root') // if folderId === null then folder goes to root
            )
            .map((m) => {
              return {
                id: m.id,
                groups: m.folderId
                  ? [m.folderId]
                  : [`Designage-${type.toLowerCase()}s`],
                type: `io.designage.${type.toLowerCase()}`,
                label: m.name,
                uri: m.secureUrl,
                // scene: m.source?.url,
                tags: getFolderTags(m.folderId),
                context: {
                  sourceId: `Designage-${type.toLowerCase()}s`,
                },
                meta: {
                  uri: m.secureUrl,
                  thumbUri: getAssetNormalThumbUrl(m.secureUrl),
                  blockType: '//ly.img.ubq/graphic',
                  fillType:
                    type === ResourceType.Image
                      ? '//ly.img.ubq/fill/image'
                      : '//ly.img.ubq/fill/video',
                  width: m.metadata?.width || 0,
                  height: m.metadata?.height || 0,
                },
                active: true, // Added missing required property
              } as CompleteAssetResult;
            })
        : [];
      return Promise.resolve({
        assets,
        currentPage: 0,
        total: assets.length,
      });
    };
  };

  updateSources = (engine: CreativeEngine, sceneMode: SceneMode) => {
    engine.asset.assetSourceContentsChanged('designageImage');
    if (sceneMode === 'Video')
      engine.asset.assetSourceContentsChanged('designageVideo');
  };

  getProfileTemplates = async () => {
    if (!this.currentUserService.currentProfile) return [];
    const { data } = await lastValueFrom(
      this.getProfileTemplatesGql.fetch({
        profileId: this.currentUserService.currentProfile?.id,
      }),
    );
    return data.profile?.template.results as Media[];
  };

  addAssetLibraryEntries = (
    instance: CreativeEditorSDK,
    sceneMode: SceneMode,
    profileFolders: Folder[] = [],
    loadTemplates: boolean = true,
  ) => {
    const getGroupTitles: (options: {
      group?: string | undefined;
      sourceId?: string | undefined;
    }) => string | undefined = ({ group, sourceId }) => {
      if (!profileFolders) return;
      const groupTitle =
        profileFolders.find(({ id }) => id === group)?.fullPath ?? group;

      // note: If no group is found, the proprty will be undifined and CE will use the ID as title
      const assetLibraryTitle = sourceId === undefined ? undefined : sourceId;

      return !group && !sourceId ? assetLibraryTitle : groupTitle;
    };
    const assetLibraries: UserInterfaceElements.AssetLibraryEntry[] = [];
    // Load image library per defualt.
    const imageLibraries: UserInterfaceElements.AssetLibraryEntry = {
      id: 'designage-image-source',
      sourceIds: ['designageImage'],
      title: getGroupTitles,
      icon: 'assets/images/image-fill.png',
      gridColumns: 3,
      previewLength: 3,
      gridBackgroundType: 'contain', // : 'contain',
      gridItemHeight: 'auto', // : 'auto',
      canAdd: true,
      canRemove: false,
    };

    // Load if video mode is selected.
    const videoAssets: UserInterfaceElements.AssetLibraryEntry = {
      id: 'designage-video-source',
      sourceIds: ['designageVideo'],
      title: getGroupTitles,
      icon: 'assets/images/video-line.png',
      gridColumns: 3,
      previewLength: 3,
      gridBackgroundType: 'contain', // : 'contain',
      gridItemHeight: 'auto', // : 'auto',
      canAdd: true,
      canRemove: false,
    };

    // Load image library per defualt.
    const stickerLibraries: UserInterfaceElements.AssetLibraryEntry = {
      id: 'Stickers',
      sourceIds: ['ly.img.sticker'],
      gridColumns: 3,
      previewLength: 3,
      canAdd: true,
      canRemove: false,
    };

    // Load if video mode is selected and templates are enabled.
    const videoTemplates: UserInterfaceElements.AssetLibraryEntry = {
      id: 'designage-video-templates',
      sourceIds: ['ly.video.template'],
      title: 'Video Templates',
      icon: 'assets/images/template-icon.svg',
    };
    const imageTemplates: UserInterfaceElements.AssetLibraryEntry = {
      id: 'designage-image-templates',
      sourceIds: ['ly.img.template'],
      title: 'Image Templates',
      icon: 'assets/images/template-icon.svg',
    };

    assetLibraries.push(imageLibraries);
    if (sceneMode === 'Design') assetLibraries.push(imageTemplates);
    if (sceneMode === 'Video') {
      assetLibraries.push(videoAssets);
      if (loadTemplates) assetLibraries.push(videoTemplates);
    }

    const getFolderTags = (folderId: Maybe<string>) => {
      const folder = folderId
        ? profileFolders.find((x) => x.id === folderId)
        : undefined;
      return folder ? folder.fullPath?.split('/') : [];
    };

    instance.ui.addAssetLibraryEntry(imageLibraries);
    if (sceneMode === 'Design')
      instance.ui.addAssetLibraryEntry(imageTemplates);
    if (sceneMode === 'Video') {
      instance.ui.addAssetLibraryEntry(videoAssets);
      if (loadTemplates) instance.ui.addAssetLibraryEntry(videoTemplates);
    }

    instance.ui.setReplaceAssetLibraryEntries(({ selectedBlocks }) => {
      if (selectedBlocks.length !== 1) {
        return [];
      }
      const [selectedBlock] = selectedBlocks;
      if (selectedBlock.fillType === '//ly.img.ubq/fill/image') {
        return ['designage-image-source'];
      }
      if (selectedBlock.fillType === '//ly.img.ubq/fill/video') {
        return ['designage-video-source'];
      }
      return [];
    });

    instance.ui.setBackgroundTrackAssetLibraryEntries([
      'designage-image-source',
      'designage-video-source',
    ]);

    instance.ui.setDockOrder([
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'designage-image-templates',
        icon: '@imgly/Template',
        label: 'libraries.ly.img.template.label',
        entries: ['designage-image-templates'],
      },
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'designage-video-templates',
        icon: '@imgly/Template',
        label: 'libraries.ly.video.template.label',
        entries: ['designage-video-templates'],
      },
      {
        id: 'ly.img.separator',
      },
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'designage-image-source',
        icon: '@imgly/Image',
        label: 'libraries.designage-image-source.label',
        entries: ['designage-image-source'],
      },
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'designage-video-source',
        icon: '@imgly/Video',
        label: 'libraries.designage-video-source.label',
        entries: ['designage-video-source'],
      },
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'ly.img.text',
        icon: '@imgly/Text',
        label: 'libraries.ly.img.text.label',
        entries: ['ly.img.text'],
      },
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'ly.img.vectorpath',
        icon: '@imgly/Shapes',
        label: 'libraries.ly.img.vectorpath.label',
        entries: ['ly.img.vectorpath'],
      },
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'ly.img.sticker',
        icon: '@imgly/Sticker',
        label: 'libraries.ly.img.sticker.label',
        entries: ['ly.img.sticker'],
      },
      { id: 'ly.img.separator' },
      {
        id: 'ly.img.assetLibrary.dock',
        key: 'ly.img.upload',
        icon: '@imgly/Upload',
        label: 'libraries.ly.img.upload.label',
        entries: ['ly.img.upload'],
      },
    ]);
  };

  async loadFonts(instance: CreativeEditorSDK) {
    instance.engine.asset.addLocalSource('profile-fonts');
    const typefaceAssets = await this.getFonts(
      this.currentUserService.currentProfile,
    );
    console.log('typefaceAssets', typefaceAssets);
    if (!typefaceAssets) return;
    // Process each typeface and its fonts
    for (const typeface of typefaceAssets) {
      instance.engine.asset.addAssetToSource('profile-fonts', typeface);
    }
  }

  getFonts = async (profile: Maybe<IProfile>) => {
    const profileId = profile?.id;
    if (!profileId) return null;

    try {
      const response = await lastValueFrom(
        this.http.get<any>(`https://data.designage.io/fonts/${profileId}.json`),
      );
      console.log('response', response);
      return response;
    } catch (error) {
      console.error('Error fetching fonts:', error);
      return null;
    }
  };
}
